import { FC } from 'react';
import { Box } from '@mui/material';
import { Button as ThemeButton } from 'modules/theme/components/button/Button';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';

import ArrowRight from 'modules/theme/components/icons/basic/TintArrowRight.svg';
import ArrowLeft from 'modules/theme/components/icons/basic/TintArrowLeft.svg';

interface IButton {
    testId?: string;
    nextPage: number;
    isDisabled?: boolean;
    isDirectionForward: boolean;
    onChange?: (newPage: number) => void;
}

export const Button: FC<IButton> = ({
    testId,
    nextPage,
    onChange,
    isDisabled,
    isDirectionForward,
}) => {

    return <ThemeButton
        fullWidth
        secondary
        testId={testId}
        disabled={isDisabled}
        onClick={() => {
            if (typeof onChange !== 'undefined') onChange(nextPage);
        }}
        text={
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexDirection={isDirectionForward ? 'row-reverse' : 'row'}
                gap={1}
            >
                <Svg icon
                    component={isDirectionForward ? ArrowRight : ArrowLeft}
                    color='backgroundPrimary'
                />
                <Text
                    variant='body2'
                    semibold
                    inheritColor
                >
                    {isDirectionForward ? 'Ďalšie' : 'Predošlé'}
                </Text>
            </Box>
        }
    />;
};