import { FC } from 'react';
import scrollToTop from 'modules/layout/scrollToTop';
import { Svg } from 'modules/theme/components/svg/Svg';
import { IconButton, Theme, Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import ArrowUp from 'modules/theme/components/icons/basic/TintArrowUp.svg';

export const ScrollToTopBtn: FC = () => {
    return <IconButton
        onClick={() => scrollToTop()}
        data-test-id='scrollToTopBtn'
        sx={{
            gap: 2,
            height: 48,
            display: 'flex',
            borderRadius: 2,
            bgcolor: 'inherit',
            alignItems: 'center',
            paddingX: { xs: 2, md: 1.5 },
            border: (theme: Theme) => '2px solid ' + theme.palette.labelPrimary.main,
        }}
    >
        <Box display={{ xs: 'none', md: 'block' }}>
            <Text variant='body3' semibold>Hore</Text>
        </Box>
        <Svg icon component={ArrowUp}/>
    </IconButton>;
};
