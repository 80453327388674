import React, { FC } from 'react';
import { Box } from '@mui/material';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { IAdditionalService } from 'components/additionalServicesList/interfaces/IAdditionalService';
import { AdditionalServiceCard } from 'components/additionalServicesList/partials/AdditionalServiceCard';

interface IAdditionalServicesCarousel {
    services: IAdditionalService[];
}

export const AdditionalServicesCarousel: FC<IAdditionalServicesCarousel> = ({
    services,
}) => {
    return (
        <Box
            display='flex'
            flexDirection='row'
            pl={2}
            mb={2}
            overflow='hidden'
            paddingBottom={2}
            sx={{
                overflowX: 'auto',
            }}
        >
            {services.map((info, index) => (
                <Box
                    key={`${info.url}-${index}`}
                    sx={{
                        marginRight: {
                            xs: 1,
                            sm: 2,
                        },
                    }}
                >
                    <AdditionalServiceCard
                        icon={info.icon}
                        name={info.name}
                        gtmLabel={info.gtmLabel}
                        url={AppConfigService.getNehnutelnostiUrl() + info.url}
                    />
                </Box>
            ))}
        </Box>
    );
};
