import { FC } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { Intro } from 'components/page/realEstate/common/additionalServices/components/partials/headline/Intro';
import AdditionalServicesIcon from 'components/page/realEstate/common/additionalServices/svg/additionalServicesIcon.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

export const Headline: FC = () => {
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Intro
            icon={<Svg component={AdditionalServicesIcon} width={isDesktop ? 100 : 75} />}
            title='Objavte všetky naše služby'
            desc={isDesktop ? 'Nehnuteľnosti.sk prinášajú veľa užitočných služieb\nako pre vlastníkov nehnuteľností, tak pre kupujúcich.' : 'Nehnuteľnosti.sk prinášajú veľa užitočných\nslužieb ako pre vlastníkov nehnuteľností,\ntak pre kupujúcich.'}
        />
    );
};
