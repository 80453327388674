import { FC } from 'react';
import Link from 'components/link/Link';
import { Text } from 'modules/theme/components/text/Text';
import { Palette } from '@mui/material';
interface IProps {
    title: string;
    url: string;
}

export const BreadcrumbsItem: FC<IProps> = ({ url, title }) => {
    if (!url) {
        return <Text
            variant='breadcrumb'
            color={(palette: Palette) => palette.labelPrimary}
            semibold
        >
            {title}
        </Text>;
    }

    return <Link href={url}>
        <Text
            variant='breadcrumb'
            color={(palette: Palette) => palette.labelSecondary}
        >
            {title}
        </Text>
    </Link>;
};
